import React, {Component} from 'react';
import '../../Home.css';

let _this;

class AirSyncProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
        // ReactGA.pageview('runandread');
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#air-sync-product" role="button"
                               onClick={() => _this.props.navigate('AirSyncProduct')}>Air Sync</a>
                        </li>
                        <li>
                            <a href="#support" role="button"
                               onClick={() => _this.props.navigate('Support')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <div>
                            <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                                 src="/airstickies.png" align="left"
                                 alt="app icon"
                                 width="128"/>
                            <div>
                                <h2>AirSync for Stickies</h2>
                                <h3>The only way to take your Apple Stickies from your Mac to your mobile phone - using
                                    WiFi, iCloud, or Dropbox.</h3>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <p align="left">
                            AirSync uploads the regular MacOS's Stickies to your mobile phone using
                            WiFi/iCloud/Dropbox.
                            The AirSync app backs your stickies up in iCloud or Dropbox!
                        </p>
                        <div className="preview">
                            <img src="/as1.webp" align="bottom" alt="airsync preview" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>TAKE YOUR STICKIES FROM MAC TO iPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://apps.apple.com/us/app/airsync-for-stickies/id543513150"
                           target="_blank"
                           rel="noopener">
                            <img src="/Mac_App_Store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/airsync-your-stickies/id554182458"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.umnes.stickies2go" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                </div>
            </div>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#air-sync-product" role="button"
                               onClick={() => _this.props.navigate('AirSyncProduct')}>Air Sync</a>
                        </li>
                        <li>
                            <a href="#support" role="button"
                               onClick={() => _this.props.navigate('Support')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default AirSyncProduct;