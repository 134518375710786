import React, {Component} from 'react';

const styles = {
    container: {
        fontSize: '1.2rem',
        lineHeight: 1.5,
        margin: '0 auto',
        maxWidth: 800,
        padding: '0 1rem',
    },
    header: {
        textAlign: 'center',
    },
    summary: {
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
};

let _this;

class RunAndReadPrivacyPolicy extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="#run-andRead-product" role="button"
                               onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                        </li>
                        <li className="selected">
                            <a href="#runandreadpolicy" role="button"
                               onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#runandreadeula" role="button"
                               onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                        </li>
                        <li>
                            <a href="#readSupport" role="button"
                               onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                        </li>
                    </ul>
                </div>
                <br/>
                <div>
                    <img style={{borderRadius: '10%'}} className="roundrect" src="/runAndRead128.png" align="left"
                         hspace="10" vspace="10" alt="app icon"
                         width="128"/>
                    <br/>
                    <h2>Run & Read: Listen on the Go</h2>
                    <h3>Ultimate Text to Speech Player</h3>
                </div>
                <br/>
                <div style={styles.container}>
                    <h1 style={styles.header}>Privacy Policy</h1>
                    <div>
                        <p>
                            This Privacy Policy describes how AnswerSolutions LLC ("we," "us," or "our") collects, uses,
                            and
                            shares information about you when you use our mobile application and website (collectively,
                            the
                            "Services"). By accessing or using our Services, you agree to be bound by this Privacy
                            Policy.
                            If you do not agree to this Privacy Policy, please do not use our Services.
                        </p>
                        <p style={styles.summary}>
                            Summary: We collect information that you provide to us, such as your email and phone model,
                            for
                            support purposes. We do not collect personal data automatically, and no login or account
                            creation is required to use the Services. Your information is used to improve the Services
                            and
                            provide support. We do not share your information unless required by law.
                        </p>
                        <p>
                            Information We Collect: We only collect information that you provide voluntarily when you
                            use
                            our Services, such as when you contact us for support. The information we may collect
                            includes
                            your email address and phone model, which are provided manually by you when reaching out to
                            us
                            for support. No other personal data is collected automatically.
                        </p>
                        <p>
                            How We Use Your Information: The information we collect is used solely to provide customer
                            support, respond to your inquiries, and improve our Services. We may contact you via email
                            to
                            resolve any issues or answer questions you have. We do not use your information for any
                            other
                            purposes.
                        </p>
                        <p>
                            We do not automatically collect or store any other personal data such as browsing history,
                            location data, or usage patterns. No login or authentication process is required to use the
                            Services, and no accounts are created.
                        </p>
                        <p>
                            Third-Party Services: Our Services may interact with third-party providers such as OpenAI,
                            Google, Apple, Amazon Web Services (AWS), and mobile providers. These third-party services
                            may
                            process certain data to facilitate the operation of the Services. However, we do not collect
                            or
                            share any personal data unless explicitly provided by you for support purposes.
                        </p>
                        <p>
                            Data Retention and Security: We retain the information you provide only as long as necessary
                            for
                            the purposes of providing support and improving the Services. We take reasonable steps to
                            protect your information from unauthorized access or disclosure. However, no method of
                            transmission over the internet or method of electronic storage is completely secure, so we
                            cannot guarantee the absolute security of your data.
                        </p>
                        <p>
                            Children's Privacy: Our Services are not directed to children under the age of 13. If you
                            are a
                            parent or guardian and believe that your child has provided us with personal information
                            without
                            your consent, please contact us at support@answersolutions.net to request the deletion of
                            that
                            information.
                        </p>
                        <p>
                            Changes to This Policy: We may update this Privacy Policy from time to time. If we make
                            material
                            changes, we will notify you via email or post a notice on our website before the changes
                            take
                            effect. Your continued use of our Services after the effective date of the changes
                            constitutes
                            your acceptance of the revised Privacy Policy.
                        </p>
                        <h2>Preloaded Books and Public Domain Content</h2>
                        <p>
                            To help you get started, we’ve preloaded a selection of classic books from public domain
                            sources, including titles from Project Gutenberg. These timeless works are legally free to
                            use
                            and share, allowing you to explore classic literature without any copyright concerns. We
                            encourage you to support authors and publishers by enjoying content that you have legally
                            acquired.
                        </p>
                        <p>
                            Run & Read is committed to respecting intellectual property rights. Please use this app only
                            for
                            your personally purchased digital content or for works that are in the public domain. For
                            preloaded books from Project Gutenberg and other sources, we adhere strictly to their
                            guidelines
                            and terms of use. You can visit the Project Gutenberg website at <a
                            href="https://gutenberg.org"
                            target="_blank">https://gutenberg.org</a> for
                            more information.
                        </p>

                        <p>
                            Contact Us: If you have any questions or concerns about this Privacy Policy or our data
                            practices, please contact us at support@answersolutions.net.
                        </p>
                    </div>

                    <br/>
                    <br/>
                    <br/>
                    <div className="fast_nav container">
                        <ul>
                            <li className="first">
                                2025 © AnswerSolutions LLC
                            </li>
                            <li>
                                <a href="#Products" role="button"
                                   onClick={() => _this.props.navigate('Products')}>Products</a>
                            </li>
                            <li >
                                <a href="#run-andRead-product" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                            </li>
                            <li className="selected">
                                <a href="#runandreadpolicy" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#runandreadeula" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                            </li>
                            <li>
                                <a href="#readSupport" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default RunAndReadPrivacyPolicy;