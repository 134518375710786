import React, {Component} from 'react';
import ReactPlayer from "react-player"
import '../../Home.css';

let _this;

class StudyCardsProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
        // ReactGA.pageview('runandread');
    }

    render() {

        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#study-cards-product" role="button"
                               onClick={() => _this.props.navigate('StudyCardsProduct')}>Study Cards</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/policy"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/#help"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <div>
                            <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} src="/study_cards.png"
                                 align="left"
                                 alt="app icon"
                                 width="128px"/>
                            <div>
                                <h2>StudyCards: Talking Flashcards</h2>
                                <h3>Helps you memorize things - create your own set of cards or ask AI to generate them,
                                    then listen on repeat and learn!</h3>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <p align="left">
                            📚 <strong>StudyCards:</strong> Experience the next level of learning with our interactive
                            talking flashcards. They mimic the feel of actual paper cards and sticky notes, yet
                            magically transform text into lifelike voices using Android's native text-to-speech feature.
                        </p>

                        <p align="left">
                            🏃 <strong>Wear OS Support:</strong> One of the few flashcard apps that work on Wear OS
                            devices, letting you study while you're running!
                        </p>

                        <p align="left">
                            🔍 <strong>Easy Card Creation:</strong> Design cards with questions on the front and answers
                            on the back directly within the app. Alternatively, create them using Excel, Numbers, or any
                            other app that supports CSV and then import. For the easiest experience, let the ChatGPT
                            Wizard handle it!
                        </p>

                        <p align="left">
                            🪄 <strong>ChatGPT Wizard:</strong> The ChatGPT Wizard simplifies flashcard creation,
                            leveraging the capabilities of a top-tier AI engine.
                        </p>

                        <p align="left">
                            🌎 <strong>Multilingual Support:</strong> Choose languages for each side of a card. Listen to
                            the app read out questions and answers – a flawless companion for eyes-free learning.
                        </p>

                        <p align="left">
                            🎯 <strong>Versatile Learning:</strong> Whether it's enhancing vocabulary, acing school
                            subjects, job interview prep, or crafting a spoken tour guide with pointers for Google or
                            Apple Maps – StudyCards is your go-to!
                        </p>

                        <h3 align="left">Why StudyCards?</h3>

                        <ul align="left">
                            <li>🎧 <strong>Engage Multiple Senses:</strong> Visualize the content, listen to it. It's a
                                winning formula for retention.
                            </li>
                            <li>🏃‍♂️ <strong>On-the-Go Learning:</strong> Exclusively with StudyCards, sync your card
                                decks to your watch with Wear OS. Learn during your runs, bike rides, or hikes – no
                                phone needed!
                            </li>
                            <li>❤️ <strong>Inclusivity:</strong> Perfectly crafted for individuals with low vision,
                                ADHD, dyslexia, and other reading challenges.
                            </li>
                            <li>🎨 <strong>Diverse Topics:</strong> Covering languages, math, songs, riddles, job
                                interview prep, and so much more – there's no limit.
                            </li>
                            <li>👆 <strong>Ease of Use:</strong> Swipe, listen, repeat. Dive deep, learn, and conquer.
                            </li>
                            <li>🚫 <strong>Hassle-Free:</strong> No accounts. No registrations. Jump right in!</li>
                        </ul>

                        <p align="left">
                            💸 <strong>StudyCards is FREE and ad-free.</strong> If you want, you can support us with
                            in-app purchases. Get started with 5 AI-generated decks on us. Need more? Generating
                            additional decks is cost-effective, starting at just 10 cents per deck.
                        </p>

                        <p align="left">
                            🚀 <strong>What's Next?</strong> We're always improving StudyCards. Expect even more
                            innovative features in the future.
                        </p>

                    </div>
                    <div className="row">
                        <div className="column">
                            <img src="/sc1.webp" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/sc2.webp" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/sc3.webp" alt="screenshot 3" width="100%"/>
                        </div>
                    </div>
                </div>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>STUDY ON YOUR IPHONE OR ANDROID</h4>
                </div>
                <div className="divider"></div>
                <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://apps.apple.com/us/app/study-cards-help-to-memorize/id1436913731"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
                       target="_blank"
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <br/>
                <br/>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                </div>
                <div className="divider"></div>
                <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://www.producthunt.com/products/studycards-app-talking-flashcards"
                       target="_blank"
                       rel="noopener">
                        <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg width="
                             height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <div align="center">
                    <a href="https://studycardsapp.com/">www.studycardsapp.com</a>
                </div>
            </div>
            <br/>
            <div className="card" id="shell1">
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/sPclf0j3xvg"
                    />
                </div>
            </div>
            <br/>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#study-cards-product" role="button"
                               onClick={() => _this.props.navigate('StudyCardsProduct')}>Study Cards</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/policy"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://studycardsapp.com/#help"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default StudyCardsProduct;