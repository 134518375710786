import React, {Component} from 'react';
import '../../Home.css';

let _this;

class FooterNavCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="fast_nav container">
            <ul>
                <li className="first">
                    2025 © AnswerSolutions LLC
                </li>
                <li>
                    <a href="https://timeslots.pro">TimeSlots Pro</a>
                </li>
                <li>
                    <a href="https://photodj.me">PhotoDj</a>
                </li>
                <li>
                    <a href="https://studycardsapp.com">StudyCards</a>
                </li>
                <li>
                    <a href="https://acallrecorder.com">A Call Recorder</a>
                </li>
                <li>
                    <a href="https://wisetalkapp.com">WiseTalk</a>
                </li>
                <li>
                    <a href="https://upwriteai.com">Up Write AI</a>
                </li>
                <li>
                    <a href="#babyTube" role="button"
                       onClick={() => _this.props.navigate('BabyTube')}>BabyTube</a>
                </li>
                <li>
                    <a href="#BabyMonitor" role="button"
                       onClick={() => _this.props.navigate('BabyMonitor')}>Baby Monitor</a>
                </li>
                <li>
                    <a href="#support" role="button"
                       onClick={() => _this.props.navigate('Support')}>Support</a>
                </li>
            </ul>
        </div>
    }
}

export default FooterNavCompact;