import React, {Component} from 'react';
import '../../Home.css';
import ReactPlayer from "react-player";

let _this;

class TimeSlotsProduct extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }

    componentDidMount() {
        // ReactGA.pageview('timeslots');
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#time-slots-productt" role="button"
                               onClick={() => _this.props.navigate('TimeSlotsProduct')}>TimeSlots</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro/privacy-policy" target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro/user-agreement" target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro" target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/timeslots128.png" align="left"
                             alt="app icon"
                             height="128"/>
                        <div>
                            <h2>Time Slots: Your Availability, Simplified.</h2>
                            <h3>Share your availability for job interviews without back-and-forth emails - hassle-free
                                and avoiding double bookings.</h3>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <p>
                        <b>Why you need Time Slots?</b><br/><br/>
                        Time Slots is designed for job seekers managing multiple interviews. It dynamically updates your
                        availability, so employers always see your latest open times, keeping your schedule
                        conflict-free
                        and stress-free.
                    </p>
                    <div>
                        <b>Unlike other services, Time Slots is tailored specifically for job seekers!</b>
                        <ul>
                            <li> Interviewers can select the duration and number of meetings.</li>
                            <li> Works for different time zones, making scheduling easy across regions.</li>
                            <li> Offers a one-week free trial with no commitment.</li>
                            <li> Provides flexible, one-time purchase options for the exact period you are job
                                hunting.
                            </li>
                            <li> No subscriptions, choose between one of the plans: one, three, six, or twelve months.
                            </li>
                            <li> Simple, straightforward, and designed to make your job search easier.</li>
                        </ul>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>START FOR FREE</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://timeslots.pro"
                           role="button"
                           className="start-free-button"
                        >Sign In</a>
                    </div>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://www.producthunt.com/posts/timeslots-your-availability-simplified?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-timeslots&#0045;your&#0045;availability&#0045;simplified"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=604952&theme=light"
                            alt="TimeSlots&#0058;&#0032;Your&#0032;Availability&#0044;&#0032;Simplified - Share&#0032;your&#0032;availability&#0032;hassle&#0045;free&#0032;&#0038;&#0032;avoid&#0032;double&#0032;bookings&#0046; | Product Hunt"
                            height="32"/></a>
                    </div>

                    <div align="center">
                        <a href="https://timeslots.pro">www.timeslots.pro</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>CANDIDATE VIEW</h4>
                </div>
                <div className="divider"></div>
                <br/>
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/lh5JrA2VzJk"
                    />
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>RECRUITER VIEW</h4>
                </div>
                <div className="divider"></div>
                <br/>
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/B3Y7MPet5Bk"
                    />
                </div>
            </div>
            <br/>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#time-slots-productt" role="button"
                               onClick={() => _this.props.navigate('TimeSlotsProduct')}>TimeSlots</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro/privacy-policy" target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro/user-agreement" target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://timeslots.pro" target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default TimeSlotsProduct;