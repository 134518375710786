import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class RunAndReadCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}}
                             className="roundrect"
                             src="/runAndRead128.png"
                             align="left"
                             alt="app icon"
                             width="128"/>
                        <div>
                            <h2>Run & Read: Listen on the Go</h2>
                            <h3>Ultimate Text to Speech player that transforms EPUB, PDF books, or any plain text into
                                an audiobook.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>LISTEN ON YOUR IPHONE OR ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/run-read-listen-on-the-go/id6741396289"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.runandread"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/run-read-ultimate-text-to-speech-player?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-run&#0045;read&#0045;ultimate&#0045;text&#0045;to&#0045;speech&#0045;player"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=939177&theme=light&t=1742003592153"
                            alt="Run&#0038;Read&#0058;&#0032;Ultimate&#0032;Text&#0032;to&#0032;Speech&#0032;Player - Transforms&#0032;EPUB&#0044;&#0032;PDF&#0044;&#0032;or&#0032;any&#0032;plain&#0032;text&#0032;into&#0032;an&#0032;audiobook&#0046; | Product Hunt"
                            className="product-hunt-button"
                            height="36"/></a>
                        <a href="#run-and-read-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('RunAndReadProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default RunAndReadCompact;