import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class TimeSlotsCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/timeslots128.png" align="left"
                             alt="app icon"
                             height="128"/>
                        <div>
                            <h2>Time Slots: Your Availability, Simplified.</h2>
                            <h3>Share your availability for job interviews without back-and-forth emails - hassle-free
                                and avoiding double bookings.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>START FOR FREE</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://timeslots.pro"
                           role="button"
                           className="start-free-button"
                        >Sign In</a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/timeslots-your-availability-simplified?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-timeslots&#0045;your&#0045;availability&#0045;simplified"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=604952&theme=light"
                            alt="TimeSlots&#0058;&#0032;Your&#0032;Availability&#0044;&#0032;Simplified - Share&#0032;your&#0032;availability&#0032;hassle&#0045;free&#0032;&#0038;&#0032;avoid&#0032;double&#0032;bookings&#0046; | Product Hunt"
                            className="product-hunt-button"
                            height="36"/></a>

                        <a href="#time-slots-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('TimeSlotsProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default TimeSlotsCompact;