import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class WifiCameraCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/app_baby_watcher.png" align="left" alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>Baby Monitor And Camera WiFi</h2>
                            <h3>Look after what is important to you! One phone is a monitor, second is a
                                camera!</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON iPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/baby-watcher-eye-wi-fi/id930583309"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.ansol.selfiepro" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="#"></a>
                        <a href="#product-baby-monitor"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('BabyMonitor')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default WifiCameraCompact;