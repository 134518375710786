import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class UpWriteCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/upwrite192.png" align="left"
                             alt="app icon"
                             width="128"/>
                        <div>
                            <h2>Up Write AI: Proofreading Keyboard</h2>
                            <h3>Writing assistance tool that uses AI to identify and correct grammar, spelling,
                                punctuation, and style errors in text.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>PROOFREAD YOUR TEXT BEFORE YOUR SEND IT ON IPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/upwrite-ai-proofread-keyboard/id6463730152"
                           target="_blank" rel="noopener">
                            <img src="/app_store.png" alt="App Store" height="36"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.upwriteai"
                           target="_blank" rel="noopener">
                            <img src="/google_play.svg" alt="Google Play" height="36"/>
                        </a>
                    </div>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>ALSO, IN SAFARI AND CHROME</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/upwrite-proofreads-your-texts/id6467161876"
                           target="_blank" rel="noopener">
                            <img src="/Mac_App_Store.svg" alt="Mac App Store" height="36"/>
                        </a>
                        <a href="https://chrome.google.com/webstore/detail/upwrite-ai-proofreading-t/dfmkhghfmnmpclilkafacfjoajlomjeo"
                           target="_blank" rel="noopener">
                            <img src="/chrome_web_store.png" alt="Chrome Web Store" height="36"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/up-write-ai" target="_blank" rel="noopener">
                            <img
                                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=413903&theme=light"
                                alt="Product Hunt"
                                className="product-hunt-button"
                                height="36"/>
                        </a>
                        <a href="#up-write-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('UpWriteProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default UpWriteCompact;
