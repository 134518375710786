import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class BabyTubeCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/babytube.png" align="left"
                             alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>BabyTube Internet Video player</h2>
                            <h3>Made for Parents & Babies, you can relax while your child watches only the content you
                                chose and
                                approved.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON iPHONE AND iPAD</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/babytube-for-parents-babies/id851093684"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <div className="product-footer">
                        <a href="#baby-tube-product"></a>
                        <a href="#product-baby-tube"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('BabyTube')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default BabyTubeCompact;