import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class ACallRecorderCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/acra.png" align="left" alt="app icon"
                             width="128"/>
                        <div>
                            <h2>A Call Recorder App</h2>
                            <h3>Records Regular Phone Calls in the BEST Possible Quality.<br/>Transforms Recorded
                                Audio files into Readable Text Documents.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON IPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/a-call-recorder/id1622051987"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.recordacall"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/a-call-recorder-transcriber-1-only?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-a&#0045;call&#0045;recorder&#0045;transcriber&#0045;1&#0045;only"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=401077&theme=light&t=1738801623385"
                            alt="A&#0032;Call&#0032;Recorder&#0032;&#0038;&#0032;Transcriber&#0032;&#0040;&#0043;1&#0032;Only&#0041; - Records&#0032;phone&#0032;calls&#0032;&#0038;&#0032;transcribes&#0032;them&#0032;into&#0032;timestamped&#0032;text | Product Hunt"
                            className="product-hunt-button"
                            height="36"/></a>
                        <a href="#a-call-recorder-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('ACallRecorderProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ACallRecorderCompact;