import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class AirSyncCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/airstickies.png" align="left"
                             alt="app icon"
                             width="128"/>
                        <div>
                            <h2>AirSync for Stickies</h2>
                            <h3>The only way to take your Apple Stickies from your Mac to your mobile phone - using
                                WiFi, iCloud, or Dropbox.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>TAKE YOUR STICKIES FROM YOUR MAC</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/airsync-for-stickies/id543513150"
                           target="_blank"
                           rel="noopener">
                            <img src="/Mac_App_Store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>TO YOUR iPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/airsync-your-stickies/id554182458"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>

                        <a href="https://play.google.com/store/apps/details?id=com.umnes.stickies2go" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://upwriteai.com"></a>
                        <a href="#air-sync-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('AirSyncProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default AirSyncCompact;