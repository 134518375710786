import React, {Component} from 'react';
import '../../Home.css';
import ReactPlayer from "react-player";

let _this;

class ACallRecorderProduct extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this
    }


    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#a-call-recorder-product" role="button"
                               onClick={() => _this.props.navigate('ACallRecorderProduct')}>A Call Recorder</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/call-recorder-and-transcriber-privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/call-recorder-and-transcriber-terms-of-use"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/all-recorder-and-transcriber-frequently-asked-questions"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/acra.png" align="left" alt="app icon"
                             width="128"/>
                        <div>
                            <h2>A Call Recorder App</h2>
                            <h3>Records Regular Phone Calls in the BEST Possible Quality.<br/>Transforms Recorded
                                Audio files into Readable Text Documents.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <p>
                        A Call Recorder is one of the best ways to <strong>record a regular phone
                        call</strong> conversation. We use the
                        <strong>IVR</strong> (Interactive Voice Response) technology to record the phone call
                        conversation in the cloud
                        with the best possible quality.
                    </p>
                    <p>
                        Also, we use the <strong>Best-In-Class ML/AI</strong> (machine learning and artificial
                        intelligence) engine to
                        <strong>transcribe phone calls</strong> in case you need to transform recorded audio files into
                        readable text that includes speaker separation, time codes, and more.
                    </p>
                    <p>
                        <strong>!!The USA And Canada Phones Only!!</strong><br/>
                        Currently, our app and service work for (+1) country code.<br/>
                        However, you can call any country and record phone conversations through the <strong>'Merge And
                        Call'</strong> button. For more information, please read the user guide on our website.

                    </p>
                    <p>
                        <strong>!!Transcription properly works for English, Spanish, and French!!</strong><br/>
                        The App will record a call in any existing language.<br/>
                        However, for now, we can properly transcribe only English, Spanish, and French.
                    </p>
                    <div>
                        <b>A Call Recorder App</b> lets you:
                        <ul>
                            <li> Record phone calls in perfect quality.</li>
                            <li> Record incoming or outgoing calls or calls you are already on, even if you are in
                                headphones.
                            </li>
                            <li> Record conference call (if your plane and the conference support merging lines).
                            </li>
                            <li> Get timestamped transcription of the recorded phone call conversation if needed.</li>
                            <li> Share recorded audio files and transcribed conversations as a text document.</li>
                            <li> Simple pricing and billing without any hidden fees.</li>
                        </ul>
                    </div>
                    <div>
                        Very simple:
                        <ul>
                            <li> Once you download the app, you receive <strong>60</strong> Credit Minutes.</li>
                            <li> SignUp with your Phone Number.</li>
                            <li> Tap the <strong>'Record A Call'</strong> button and choose or dial the destination
                                number.
                            </li>
                            <li> Tap the <strong>'Merge And Record'</strong> button on the bottom left if you are
                                already on the call.
                            </li>
                            <li> Go to the history of recorded calls and playback the recorded audio files whenever you
                                want.
                            </li>
                            <li> Request Transcription to transform recorded audio files into readable text documents.
                            </li>
                            <li> Share audio and text files through email.</li>
                            <li> Purchase extra minutes when you need them.</li>
                        </ul>
                    </div>
                    <p>
                        <strong>
                            <span className="crimson_text">!!No Ads!!</span><br/>
                            <span className="crimson_text">!!No Subscription!!</span>
                        </strong>
                    </p>
                    <p>
                        Mobile app is available for modern Apple and Android phones.
                    </p>
                </div>
                <div className="row">
                    <div className="column">
                        <img src="/screen1.webp" alt="screenshot 1" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen2.webp" alt="screenshot 2" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen3.webp" alt="screenshot 3" width="100%"/>
                    </div>
                    <div className="column">
                        <img src="/screen4.webp" alt="screenshot 4" width="100%"/>
                    </div>
                </div>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>WORKS ON IPHONE AND ANDROID</h4>
                </div>
                <div className="divider"></div>
                <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://apps.apple.com/us/app/a-call-recorder/id1622051987"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.answersolutions.recordacall"
                       target="_blank"
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
                <br/>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                </div>
                <div className="divider"></div>
                <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://www.producthunt.com/posts/a-call-recorder-transcriber-1-only?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-a&#0045;call&#0045;recorder&#0045;transcriber&#0045;1&#0045;only"
                       target="_blank"><img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=401077&theme=light&t=1738801623385"
                        alt="A&#0032;Call&#0032;Recorder&#0032;&#0038;&#0032;Transcriber&#0032;&#0040;&#0043;1&#0032;Only&#0041; - Records&#0032;phone&#0032;calls&#0032;&#0038;&#0032;transcribes&#0032;them&#0032;into&#0032;timestamped&#0032;text | Product Hunt"
                        height="32"/></a>
                </div>
                <br/>
                <br/>
                <div align="center">
                    <a href="https://acallrecorder.com">www.acallrecorder.com</a>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/Bz7Ka9ujNjQ"
                    />
                </div>
            </div>
            <br/>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#a-call-recorder-product" role="button"
                               onClick={() => _this.props.navigate('ACallRecorderProduct')}>A Call Recorder</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/call-recorder-and-transcriber-privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/call-recorder-and-transcriber-terms-of-use"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://acallrecorder.com/all-recorder-and-transcriber-frequently-asked-questions"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}


export default ACallRecorderProduct;