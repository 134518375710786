import React, {Component} from 'react';
import Products from "./Products";
import BabyTube from "./products/babytube/BabyTube";
import PrivacyBabyTube from "./products/babytube/privacy/PrivacyBabyTube";
import AdIos from "./AdIos"
import AdAndroid from "./AdAndroid";
import ContactUs from "./ContactUs";
import BabyMonitor from "./products/wificamera/BabyMonitor"
import PrivacyBabyMonitor from "./products/wificamera/privacy/PrivacyBabyMonitor";
import AgreementBabyMonitor from "./products/wificamera/agreement/AgreementBabyMonitor";
import RunAndReadUserAgreement from "./products/runandread/agreement";
import RunAndReadPrivacyPolicy from "./products/runandread/privacy";
import RunAndReadSupport from "./products/runandread/support/index";
import RunAndReadProduct from "./products/runandread";
import AirSyncProduct from "./products/airsync";
import PhotoDjProduct from "./products/photodj";
import StudyCardsProduct from "./products/studycards";
import ACallRecorderProduct from "./products/callrecorder";
import WiseTalkProduct from "./products/wisetalk";
import UpWriteProduct from "./products/upwrite";
import TimeSlotsProduct from "./products/timeslots";
import VoiceTranslatorProduct from "./products/translator";

let _this;

class ASolApp extends Component {
    constructor(props, context) {
        super(props, context);

        let init_screen = 'Products';//'Home';
        // console.log('ASolApp.constructor=>'+window.location.hash);
        if (window.location.hash === '#support') {
            init_screen = 'Support';
        } else if (window.location.hash === '#adios') {
            init_screen = 'AdIos';
        } else if (window.location.hash === '#adandroid') {
            init_screen = 'AdAndroid';
        } else if (window.location.hash === '#PrivacyBabyMonitor' || window.location.pathname.endsWith('privacy-baby-monitor')) {
            init_screen = 'PrivacyBabyMonitor';
        } else if (window.location.hash === '#AgreementBabyMonitor' || window.location.pathname.endsWith('agreement-baby-monitor')) {
            init_screen = 'AgreementBabyMonitor';
        // } else if (window.location.hash === '#amfree' || window.location.pathname.endsWith('amfree')) {
        //     init_screen = 'JobSeekerCard';
        } else if (window.location.hash === '#runandreadpolicy' || window.location.pathname.endsWith('run-and-read-policy')) {
            init_screen = 'RunAndReadPolicy';
        } else if (window.location.hash === '#runandreadeula' || window.location.pathname.endsWith('run-and-read-eula')) {
            init_screen = 'RunAndReadEULA';
        } else if (window.location.hash === '#readSupport' || window.location.pathname.endsWith('run-and-read-support')) {
            init_screen = 'RunAndReadSupport';
        } else if (window.location.hash === '#run-andRead-product' || window.location.pathname.endsWith('run-and-read-product')) {
            init_screen = 'RunAndReadProduct';
        // } else if (window.location.hash === '#baby-tube-product' || window.location.pathname.endsWith('baby-tube-product')) {
        //     init_screen = 'BabyTubeProduct';
        // } else if (window.location.hash === '#wifi-camera-product' || window.location.pathname.endsWith('wifi-camera-product')) {
        //     init_screen = 'WifiCameraProduct';
        } else if (window.location.hash === '#air-sync-product' || window.location.pathname.endsWith('air-sync-product')) {
            init_screen = 'AirSyncProduct';
        } else if (window.location.hash === '#photo-dj-product' || window.location.pathname.endsWith('photo-dj-product')) {
            init_screen = 'PhotoDjProduct';
        } else if (window.location.hash === '#study-cards-product' || window.location.pathname.endsWith('study-cards-product')) {
            init_screen = 'StudyCardsProduct';
        } else if (window.location.hash === '#a-call-recorder-product' || window.location.pathname.endsWith('a-call-recorder-product')) {
            init_screen = 'ACallRecorderProduct';
        } else if (window.location.hash === '#wise-talk-product' || window.location.pathname.endsWith('wise-talk-product')) {
            init_screen = 'WiseTalkProduct';
        } else if (window.location.hash === '#up-write-product' || window.location.pathname.endsWith('up-write-product')) {
            init_screen = 'UpWriteProduct';
        }else if (window.location.hash === '#time-slots-product' || window.location.pathname.endsWith('time-slots-product')) {
            init_screen = 'TimeSlotsProduct';
        }else if (window.location.hash === '#voice-translator-product' || window.location.pathname.endsWith('voice-translator-product')) {
            init_screen = 'VoiceTranslatorProduct';
        }


        this.state = {
            target: init_screen
        };
        _this = this;

    }

    componentDidMount() {
        console.log('componentDidMount=>');
    }

    navigate(new_target) {
        console.log('navigate=>' + new_target);
        _this.setState({
            target: new_target,
        });
    };

    render() {

        let screen = null;
        if (this.state.target === 'BabyTube' || window.location.pathname.endsWith('product-baby-tube')) {
            screen = <BabyTube navigate={this.navigate}/>
        } else if (this.state.target === 'PrivacyBabyTube' || window.location.pathname.endsWith('privacy-baby-tube')) {
            screen = <PrivacyBabyTube navigate={this.navigate}/>
        } else if (this.state.target === 'BabyMonitor' || window.location.pathname.endsWith('product-baby-monitor')) {
            screen = <BabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'PrivacyBabyMonitor' || window.location.pathname.endsWith('privacy-baby-monitor')) {
            screen = <PrivacyBabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'AgreementBabyMonitor' || window.location.pathname.endsWith('agreement-baby-monitor')) {
            screen = <AgreementBabyMonitor navigate={this.navigate}/>
        } else if (this.state.target === 'AdIos') {
            screen = <AdIos navigate={this.navigate}/>
        } else if (this.state.target === 'AdAndroid') {
            screen = <AdAndroid navigate={this.navigate}/>
        } else if (this.state.target === 'Support') {
            screen = <ContactUs navigate={this.navigate}/>
        } else if (this.state.target === 'RunAndReadEULA') {
            screen = <RunAndReadUserAgreement navigate={this.navigate}/>
        } else if (this.state.target === 'RunAndReadPolicy') {
            screen = <RunAndReadPrivacyPolicy navigate={this.navigate}/>
        } else if (this.state.target === 'RunAndReadSupport' || window.location.pathname.endsWith('run-and-read-support')) {
            screen = <RunAndReadSupport navigate={this.navigate}/>
        } else if (this.state.target === 'RunAndReadProduct' || window.location.pathname.endsWith('run-and-read-product')) {
            screen = <RunAndReadProduct navigate={this.navigate}/>
        // } else if (this.state.target === 'BabyTubeProduct' || window.location.pathname.endsWith('baby-tube-product')) {
        //     screen = <BabyTubeProduct navigate={this.navigate}/>
        // } else if (this.state.target === 'WifiCameraProduct' || window.location.pathname.endsWith('wifi-camera-product')) {
        //     screen = <WifiCameraProduct navigate={this.navigate}/>
        } else if (this.state.target === 'AirSyncProduct' || window.location.pathname.endsWith('air-sync-product')) {
            screen = <AirSyncProduct navigate={this.navigate}/>
        } else if (this.state.target === 'PhotoDjProduct' || window.location.pathname.endsWith('photo-dj-product')) {
            screen = <PhotoDjProduct navigate={this.navigate}/>
        } else if (this.state.target === 'StudyCardsProduct' || window.location.pathname.endsWith('study-cards-product')) {
            screen = <StudyCardsProduct navigate={this.navigate}/>
        } else if (this.state.target === 'ACallRecorderProduct' || window.location.pathname.endsWith('a-call-recorder-product')) {
            screen = <ACallRecorderProduct navigate={this.navigate}/>
        } else if (this.state.target === 'WiseTalkProduct' || window.location.pathname.endsWith('wise-talk-product')) {
            screen = <WiseTalkProduct navigate={this.navigate}/>
        } else if (this.state.target === 'UpWriteProduct' || window.location.pathname.endsWith('up-write-product')) {
            screen = <UpWriteProduct navigate={this.navigate}/>
        } else if (this.state.target === 'TimeSlotsProduct' || window.location.pathname.endsWith('time-slots-product')) {
            screen = <TimeSlotsProduct navigate={this.navigate}/>
        } else if (this.state.target === 'VoiceTranslatorProduct' || window.location.pathname.endsWith('voice-translator-product')) {
            screen = <VoiceTranslatorProduct navigate={this.navigate}/>
        } else {
            screen = <Products navigate={this.navigate}/>
        }

        return (
            <div className="App">
                {screen}
            </div>
        );
    }
}

export default ASolApp;
