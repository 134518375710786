import React, {Component} from 'react';
import '../../Home.css';

let _this;

class WiseTalkProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
        // ReactGA.pageview('runandread');
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#wise-talk-product" role="button"
                               onClick={() => _this.props.navigate('WiseTalkProduct')}>Wise Talk AI</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/user-agreement"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/support"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/wt_logo192.png" align="left"
                             hspace="10" vspace="10" alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>WiseTalk App</h2>
                            <h3>Voice-activated assistant that combines the power of the best AI engine (ChatGPT) with
                                the convenient speech recognition and speech synthesis engines embedded in modern
                                phones, developed by Apple and Google.</h3>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <p>
                        Voice-activated chat assistant powered by cutting-edge Artificial Intelligence!
                        Multilingual support!
                        Experience nearly hands-free AI conversations anywhere!

                        WiseTalk: Engaging AI Conversations, Effortlessly Nearly Hands-Free

                        Discover the next generation of AI interactions with WiseTalk—an innovative app that seamlessly
                        blends cutting-edge Large Language Model based AI with speech-to-text and text-to-speech
                        technology to deliver a uniquely engaging and natural conversational experience.
                    </p>

                    <div>
                        Key Features:
                        <ul>
                            <li> Voice-Based AI Interactions: WiseTalk harnesses speech-to-text and text-to-speech
                                engines to facilitate intuitive, voice-driven conversations with an advanced AI language
                                model.
                            </li>
                            <li> Real-Time Assistance: Obtain real-time advice, information, and support across a wide
                                array of topics, making AI wisdom accessible wherever you are.
                            </li>
                            <li> Local Speech Processing: WiseTalk prioritizes user privacy and convenience, performing
                                speech recognition and synthesis directly on your device—no need to send voice data to
                                the cloud.
                            </li>
                            <li> Reliable Connectivity: Designed for optimal performance in areas with poor internet
                                connections, WiseTalk exchanges text-only data to ensure a smooth user experience.
                            </li>
                            <li> Multilingual Support: WiseTalk embraces a global audience with its ability to recognize
                                and synthesize any language supported by your device.
                            </li>
                            <li> Customizable AI Roles: Tailor the AI's response style to your needs, from simple
                                explanations by an elementary school teacher to in-depth analyses by a college
                                professor.
                            </li>
                            <li> Token-Based Access: Enjoy an initial 10K tokens for free, and refill your balance with
                                affordable token packages. Get even more value with our subscription service, doubling
                                your tokens and providing access to conversation history, and more.
                            </li>
                        </ul>
                        Experience the future of AI-driven conversations with WiseTalk, and unlock the full potential of
                        voice-based interactions on your Apple device.
                        Download WiseTalk today and join the AI conversation revolution.
                    </div>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img src="/screenshots.webp" alt="screenshot 1" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON IPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/wisetalk-ai-powered-voice-chat/id6447212540"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://www.producthunt.com/posts/wisetalk?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-wisetalk"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400044&theme=light&t=1738801395160"
                            alt="WiseTalk - Say&#0044;&#0032;send&#0044;&#0032;listen&#0044;&#0032;marvel&#0044;&#0032;repeat | Product Hunt"
                            height="32"/></a>
                    </div>
                    <div align="center">
                        <a href="https://wisetalkapp.com">www.wisetalkapp.com</a>
                    </div>
                </div>
            </div>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#wise-talk-product" role="button"
                               onClick={() => _this.props.navigate('WiseTalkProduct')}>Wise Talk AI</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/user-agreement"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/support"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

            export default WiseTalkProduct;