import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class WiseTalkCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/wt_logo192.png" align="left"
                             hspace="10" vspace="10" alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>WiseTalk App</h2>
                            <h3>Voice-activated assistant that combines the power of the best AI engine (ChatGPT) with
                                the convenient speech recognition and speech synthesis engines embedded in modern
                                phones, developed by Apple and Google.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON IPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/wisetalk-ai-powered-voice-chat/id6447212540"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/wisetalk?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-wisetalk"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400044&theme=light&t=1738801395160"
                            alt="WiseTalk - Say&#0044;&#0032;send&#0044;&#0032;listen&#0044;&#0032;marvel&#0044;&#0032;repeat | Product Hunt"
                            className="product-hunt-button"
                            height="36"/></a>
                        <a href="#wise-talk-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('WiseTalkProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default WiseTalkCompact;