import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class StudyCardsCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} src="/study_cards.png" align="left"
                             alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>StudyCards: Talking Flashcards</h2>
                            <h3>Helps you memorize things - create your own set of cards or ask AI to generate them,
                                then listen on repeat and learn!</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>STUDY ON YOUR IPHONE OR ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/study-cards-help-to-memorize/id1436913731"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36px" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/products/studycards-app-talking-flashcards"
                           target="_blank"
                           rel="noopener">
                            <img alt="ph" src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg width="
                                 className="product-hunt-button"
                                 height="36"/>
                        </a>
                        <a href="#study-cards-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('StudyCardsProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default StudyCardsCompact;