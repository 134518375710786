import React, {Component} from 'react';
import '../../Home.css';

let _this;

class RunAndReadProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#run-andRead-product" role="button"
                               onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                        </li>
                        <li>
                            <a href="#runandreadpolicy" role="button"
                               onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#runandreadeula" role="button"
                               onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                        </li>
                        <li>
                            <a href="#readSupport" role="button"
                               onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}}
                             className="roundrect"
                             src="/runAndRead128.png"
                             align="left"
                             alt="app icon"
                             width="128"/>
                        <div>
                            <h2>Run & Read: Listen on the Go</h2>
                            <h3>Ultimate Text to Speech player that transforms EPUB, PDF books, or any plain text into
                                an audiobook.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <p>
                        <b>Why you need Run & Read?</b><br/><br/>
                        Run & Read is a free, user-friendly app that transforms your favorite texts into engaging audio
                        using Apple's and Google Android powerful embedded text-to-speech engines. Perfect for students,
                        professionals, and book
                        lovers on the go, Run & Read makes it effortless to listen to content anytime, anywhere.
                        Convert text, PDF, and EPUB files into speech with Run & Read! Enjoy multiple voices, adjustable
                        playback, and bookmarking. Download now—on the go!
                    </p>
                    <div className="row">
                        <div className="column">
                            <img src="/run&read.1.webp" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/run&read.2.webp" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/run&read.3.webp" alt="screenshot 3" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/run&read.4.webp" alt="screenshot 4" width="100%"/>
                        </div>
                    </div>
                    <div>
                        <b>Key Features</b>
                        <ul>
                            <li><b>Multi-Format Support:</b> Convert PDFs, EPUBs, TXT files, or even text copied from
                                your
                                clipboard into speech.
                            </li>
                            <li><b>Intelligent Preprocessing:</b> Easily skip unreadable sections - such as tables of
                                contents
                                or
                                metadata - with quick and intuitive controls, ensuring a smooth and uninterrupted
                                listening
                                experience.
                            </li>
                            <li><b>Customizable Narration:</b> Choose from a variety of voices and adjust the speaking
                                rate
                                for
                                each text or book to match your preferences.
                            </li>
                            <li><b>One-Touch Bookmarks:</b> Easily mark important sections while listening and revisit
                                them
                                later
                                from your list of saved bookmarks.
                            </li>
                            <li><b>Seamless Playback Controls:</b> Control playback directly from the lock screen, Apple
                                Watch, or Android wearable for a truly hands-free experience.
                            </li>
                            <li><b>User-Friendly Interface:</b> Navigate effortlessly through your reading material with
                                intuitive
                                controls designed for a smooth text-to-speech experience.
                            </li>
                        </ul>
                    </div>
                    <br/>
                    <div>
                        <b>Legal & Copyright Notice</b>
                        <p>Run & Read is committed to respecting intellectual property rights. Please use this app only
                            for
                            your personally purchased digital content or for works that are in the public domain. For
                            preloaded
                            books from Project Gutenberg and other sources, we adhere strictly to their guidelines and
                            terms
                            of
                            use.</p>
                    </div>

                </div>
                <br/>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                </div>
                <div className="divider"></div>
                <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                    <a href="https://www.producthunt.com/posts/run-read-ultimate-text-to-speech-player?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-run&#0045;read&#0045;ultimate&#0045;text&#0045;to&#0045;speech&#0045;player"
                       target="_blank"><img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=939177&theme=light&t=1742003592153"
                        alt="Run&#0038;Read&#0058;&#0032;Ultimate&#0032;Text&#0032;to&#0032;Speech&#0032;Player - Transforms&#0032;EPUB&#0044;&#0032;PDF&#0044;&#0032;or&#0032;any&#0032;plain&#0032;text&#0032;into&#0032;an&#0032;audiobook&#0046; | Product Hunt"
                        className="product-hunt-button"
                        height="32"/></a>
                </div>
                <br/>
                <div style={{margin: '0 24px 0 24px'}}>
                    <h4>WORKS ON IPHONE AND ANDROID</h4>
                </div>
                <div className="divider"></div>
                <br/>
                <div align="center" hspace="10" vspace="10">
                    <a href="https://apps.apple.com/us/app/run-read-listen-on-the-go/id6741396289"
                       target="_blank"
                       rel="noopener">
                        <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.answersolutions.runandread"
                       target="_blank"
                        // onClick={()=>{alert("Run & Read for Android is coming soon!")}}
                       rel="noopener">
                        <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                    </a>
                </div>
            </div>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#run-andRead-product" role="button"
                               onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                        </li>
                        <li>
                            <a href="#runandreadpolicy" role="button"
                               onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#runandreadeula" role="button"
                               onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                        </li>
                        <li>
                            <a href="#readSupport" role="button"
                               onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default RunAndReadProduct;