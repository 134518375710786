import React, {Component} from 'react';
import '../../Home.css';
import ReactPlayer from "react-player";

let _this;

class UpWriteProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
        // ReactGA.pageview('runandread');
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#up-write-product" role="button"
                               onClick={() => _this.props.navigate('UpWriteProduct')}>Up Write AI</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/privacy-policy" target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/user-agreement" target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/support" target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/upwrite192.png" align="left"
                             alt="app icon"
                             width="128"/>
                        <div>
                            <h2>Up Write AI: Proofreading Keyboard</h2>
                            <h3>Writing assistance tool that uses AI to identify and correct grammar, spelling,
                                punctuation, and style errors in text.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <p>
                        UpWrite AI Editor & Keyboard is a digital writing assistance tool that uses artificial
                        intelligence and natural language processing to identify and correct grammar, spelling,
                        punctuation, and style errors in text. Additionally, it can adjust the tone, clarity,
                        engagement, and delivery of a piece of writing. It's specifically designed for mobile users on
                        both iOS and Android platforms. This tool is ideal for writers, students, professionals, and
                        anyone looking to enhance the quality and clarity of their written communication. With a focus
                        on mobile optimization and voice-to-text (dictation) capabilities, UpWrite AI stands out as a
                        premier choice for those seeking top-tier writing assistance on the go.
                    </p>
                    <div>
                        Key Features:
                        <ul>
                            <li> Advanced Text Editing: Ability to mold your text to desired tones, styles, and
                                audiences, ensuring versatility in your communication.
                            </li>
                            <li> Proofreading Keyboard Extension: Works harmoniously across all apps on your phone,
                                ensuring consistent quality in all your writings. Is an embedded companion keyboard
                                extension that works in Mail, Messenger, Messages, LinkedIn, X (Twitter), Gmail, etc.
                            </li>
                            <li> In-App Voice Input: A voice-to-text tool designed for those on-the-go moments,
                                transforming your spoken words into polished written content.
                            </li>
                            <li> Educative Corrections: Understand your mistakes as UpWrite AI not only corrects but
                                also explains, fostering continuous improvement in your writing skills.
                            </li>
                        </ul>
                        While most competing apps tie you down with subscriptions, we believe in a 'Pay as you Go'
                        philosophy. No hidden fees. No automatic renewals. We prioritize your needs, giving you the
                        power to decide when and for what you pay. Experience the freedom of only paying for what you
                        actually use.
                    </div>
                    <br/>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img src="/upwrite_screens.webp" alt="screenshot 1" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>PROOFREAD YOUR TEXT BEFORE YOUR SEND IT (iPHone/Android/Safari/Chrome)</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/upwrite-ai-proofread-keyboard/id6463730152"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/upwrite-proofreads-your-texts/id6467161876"
                           target="_blank"
                           rel="noopener">
                            <img src="/Mac_App_Store.svg" alt="macappstore" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.upwriteai"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="32px" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://chrome.google.com/webstore/detail/upwrite-ai-proofreading-t/dfmkhghfmnmpclilkafacfjoajlomjeo"
                           target="_blank"
                           rel="noopener">
                            <img src="/chrome_web_store.png" alt="chrome web store" height="32px" hspace="5"
                                 vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>SUPPORT OUR LAUNCH ON PRODUCTHUNT</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://www.producthunt.com/posts/up-write-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-timeslots&#0045;your&#0045;availability&#0045;simplified"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=413903&theme=light"
                            alt="Product Hunt"
                            height="32"/></a>
                    </div>
                    <br/>
                    <div align="center">
                        <a href="https://upwriteai.com">www.upwriteai.com</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/JkbO_3rjCPo"
                    />
                </div>
            </div>
            <br/>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#up-write-product" role="button"
                               onClick={() => _this.props.navigate('UpWriteProduct')}>Up Write AI</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/privacy-policy" target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/user-agreement" target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://upwriteai.com/support" target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default UpWriteProduct;