import React, {Component} from 'react';
import '../../../Home.css';
let _this;

class RunAndReadSupport extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

        _this.state = {
            faq: [
                {
                    question: 'Q: What is Run & Read?',
                    answer: 'A: Run & Read is a free, user-friendly text-to-speech app that converts PDFs, EPUBs, TXT files, or copied text into engaging audio using Apple’s powerful embedded text-to-speech engine.'
                },
                {
                    question: 'Q: What types of files can Run & Read convert to audio?',
                    answer: 'A: Run & Read supports PDFs, EPUBs, TXT files, and any copied text that you want to listen to.'
                },
                {
                    question: 'Q: Does Run & Read support public domain books?',
                    answer: 'A: Yes! Run & Read includes a curated library of classic public domain books, including titles from Project Gutenberg.'
                },
                {
                    question: 'Q: Is Run & Read free to use?',
                    answer: 'A: Yes, Run & Read is completely free to use. However, please use it only for personally purchased content or public domain works.'
                },
                {
                    question: 'Q: Can I adjust the voice and speed while listening?',
                    answer: 'A: Yes! You can choose from various voices and adjust the speaking rate to your preference for a customized listening experience.'
                },
                {
                    question: 'Q: How can I mark important sections of a text?',
                    answer: 'A: You can use one-touch bookmarks to easily mark sections and revisit them later from your saved bookmarks list.'
                },
                {
                    question: 'Q: Can I control playback from my Lock Screen or Apple Watch?',
                    answer: 'A: Yes, you can seamlessly control playback from both your Lock Screen and Apple Watch for a hands-free experience.'
                },
                {
                    question: 'Q: Can I skip over unreadable sections like tables of contents?',
                    answer: 'A: Yes! Run & Read offers intelligent preprocessing, allowing you to easily skip sections like tables of contents and metadata.'
                },
                {
                    question: 'Q: How do I get started with Run & Read?',
                    answer: 'A: Simply download the app and start converting your favorite texts into audio. Enjoy classic public domain books, or load your own PDFs, EPUBs, or TXT files.'
                },
                {
                    question: 'Q: How do I contact customer support?',
                    answer: 'A: You can contact us via email at support@answersolutions.net.'
                },
            ]
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="#run-andRead-product" role="button"
                               onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                        </li>
                        <li>
                            <a href="#runandreadpolicy" role="button"
                               onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#runandreadeula" role="button"
                               onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                        </li>
                        <li className="selected">
                            <a href="#readSupport" role="button"
                               onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                        </li>
                    </ul>
                </div>
                <br/>
                <div>
                    <img style={{borderRadius: '10%'}} className="roundrect" src="/runAndRead128.png" align="left"
                         hspace="10" vspace="10" alt="app icon"
                         width="128"/>
                    <br/>
                    <h2>Run & Read: Listen on the Go</h2>
                    <h3>Ultimate Text to Speech Player</h3>
                </div>
                <br/>
                <div style={{padding: '20px', fontFamily: 'Arial, sans-serif'}}>
                    <div style={{marginBottom: '40px'}}>
                        <h2>FAQ And Support</h2>
                        {this.state.faq.map((item, index) => (
                            <div key={index} style={{marginBottom: '20px'}}>
                                <h3 style={{marginBottom: '8px'}}>{item.question}</h3>
                                <p>{item.answer}</p>
                                <hr style={{margin: '20px 0'}}/>
                            </div>
                        ))}
                    </div>

                    <div style={{marginBottom: '40px'}}>
                        <h2>Need Help?</h2>
                        <p>Contact us via email at support@answersolutions.net for assistance.</p>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div className="fast_nav container">
                        <ul>
                            <li className="first">
                                2025 © AnswerSolutions LLC
                            </li>
                            <li>
                                <a href="#Products" role="button"
                                   onClick={() => _this.props.navigate('Products')}>Products</a>
                            </li>
                            <li>
                                <a href="#run-andRead-product" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                            </li>
                            <li>
                                <a href="#runandreadpolicy" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                            </li>
                            <li>
                                <a href="#runandreadeula" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                            </li>
                            <li className="selected">
                                <a href="#readSupport" role="button"
                                   onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default RunAndReadSupport;