import React, {Component} from 'react';
import '../../Compact.css';

let _this;

class PhotoDjCompact extends Component {

    constructor(props, context) {
        super(props);
        _this = this;
    }

    render() {
        return <div className="product-card">
            <div className="product-card-content">
                <div className="product-info">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/photo_dj.png" align="left"
                             alt="app icon" width="128"/>
                        <div>
                            <h2>PhotoDj - Photos on Big Screen</h2>
                            <h3>Slideshow on a Bigger Screen, the easiest way to display the photos from your phone
                                gallery, on Smart TV, PC, Mac, Tablet, or another phone.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>PRESENT FROM IPHONE OR ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://itunes.apple.com/us/app/photodj/id1325400635?ls=1&amp;mt=8"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.nes.pdj.photodj" target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>PRESENT ON ANDROID OR APPLE TV, OR ANY SMART TV</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/to/app/photodj-your-photos-on-tv/id1325400635?platform=appleTV"
                           target="_blank"
                           rel="noopener">
                            <img src="/apple_tv.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <div className="divider"></div>
                    <div className="product-footer">
                        <a href="https://www.producthunt.com/posts/photodj-present-gallery-on-a-big-screen?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-photodj&#0045;present&#0045;gallery&#0045;on&#0045;a&#0045;big&#0045;screen"
                           target="_blank"><img
                            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=423021&theme=light&t=1738801751667"
                            alt="PhotoDJ&#0058;&#0032;Present&#0032;Gallery&#0032;on&#0032;a&#0032;Big&#0032;Screen - Transform&#0032;photos&#0032;from&#0032;your&#0032;phone&#0032;gallery&#0032;into&#0032;a&#0032;presentation | Product Hunt"
                            className="product-hunt-button"
                            height="36"/></a>
                        <a href="#photo-dj-product"
                           role="button"
                           className="learn-more-button"
                           onClick={() => _this.props.navigate('PhotoDjProduct')}
                        >Learn More →</a>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default PhotoDjCompact;