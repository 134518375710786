import React, {Component} from 'react';

const styles = {
    container: {
        fontSize: '1.2rem',
        lineHeight: 1.5,
        margin: '0 auto',
        maxWidth: 800,
        padding: '0 1rem',
    },
    header: {
        textAlign: 'center',
    },
    summary: {
        fontWeight: 'bold',
        marginBottom: '1rem',
    },
};

let _this;

class RunAndReadUserAgreement extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {

    }

    render() {
    return (
        <div className="container">
            <div className="fast_nav container">
                <ul>
                    <li className="first">
                        <a href="#" role="button"
                           onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                    </li>
                    <li>
                        <a href="#run-andRead-product" role="button"
                           onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                    </li>
                    <li>
                        <a href="#runandreadpolicy" role="button"
                           onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                    </li>
                    <li className="selected">
                        <a href="#runandreadeula" role="button"
                           onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                    </li>
                    <li>
                        <a href="#readSupport" role="button"
                           onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                    </li>
                </ul>
            </div>
            <br/>
            <div>
                <img style={{borderRadius: '10%'}} className="roundrect" src="/runAndRead128.png" align="left"
                     hspace="10" vspace="10" alt="app icon"
                     width="128"/>
                <br/>
                <h2>Run & Read: Listen on the Go</h2>
                <h3>Ultimate Text to Speech Player</h3>
            </div>
            <br/>
            <div style={styles.container}>
            <h1 style={styles.header}>Terms of Use</h1>
                <p style={styles.summary}>
                    By using our Services, you agree to comply with these Terms of Use. You agree to use our Services
                    only for lawful purposes, and you are responsible for all content you submit. Some services may be
                    subject to fees, and we may terminate your access if you violate these Terms.
                </p>

                <h2>User Conduct</h2>
                <p>
                    You agree to use our Services only for lawful purposes and in compliance with these Terms. You are
                    responsible for all content that you submit, post, or otherwise make available through our Services.
                    You warrant that such content is accurate, not confidential, and not in violation of any laws or
                    third-party rights.
                </p>
                <p>
                    Although the app is not specifically designed for children, users who are at least 13 years old can
                    use the Services. If you are under 18, you must have permission from your parent or legal guardian
                    to use the Services. If you use the Services on behalf of another person or entity, you must have
                    the authority to accept these Terms on their behalf.
                </p>
                <p>
                    You must provide accurate and complete information when using our Services. You may not share your
                    access credentials or account with others, and you are responsible for all activities that occur
                    using your credentials.
                </p>

                <h2>Fees and Payments</h2>
                <p>
                    Our Services are currently free to use. However, we reserve the right to charge fees for access to
                    certain features or services in the future. Should this occur, we will notify you in advance. For
                    now, you can use the Services without any charges.
                </p>

                <h2>Intellectual Property</h2>
                <p>
                    All content and materials available through our Services, including but not limited to the *Run &
                    Read* app, trademarks, logos, and designs, are the property of AnswerSolutions LLC or our licensors
                    and are protected by copyright, trademark, and other intellectual property laws. You agree not to
                    reproduce, distribute, modify, or create derivative works from any content or materials from our
                    Services.
                </p>

                <h2>Disclaimer of Warranties</h2>
                <p>
                    Our Services and all content and materials available through our Services are provided on an "as is"
                    and "as available" basis, without warranties of any kind, either express or implied, including
                    without limitation implied warranties of merchantability, fitness for a particular purpose, or
                    non-infringement.
                </p>
                <p>
                    We do not guarantee that our Services will be uninterrupted, error-free, or free of viruses or other
                    harmful components.
                </p>

                <h2>Third-Party Services</h2>
                <p>
                    Our Services may utilize third-party service providers such as OpenAI, Google, Apple, and Amazon Web
                    Services (AWS), and may rely on mobile communication infrastructure for internet access. We are not
                    responsible for any downtime, latency, or other issues arising from the use of these third-party
                    services.
                </p>

                <h2>Limitation of Liability</h2>
                <p>
                    In no event shall AnswerSolutions LLC or its affiliates, officers, directors, employees, or agents
                    be liable for any direct, indirect, incidental, special, or consequential damages arising from or
                    related to your use or inability to use our Services, or for any content or materials obtained
                    through our Services. This limitation applies regardless of the legal theory, whether in contract,
                    tort, or otherwise.
                </p>

                <h2>Modification of Terms</h2>
                <p>
                    We may update these Terms at any time by posting revised Terms on our website or mobile application.
                    Your continued use of our Services after such modifications constitutes your acceptance of the
                    revised Terms.
                </p>

                <h2>Termination</h2>
                <p>
                    We reserve the right to terminate or suspend your access to our Services at any time, without notice
                    and for any reason, including if you breach these Terms.
                </p>

                <h2>Governing Law</h2>
                <p>
                    These Terms and your use of our Services are governed by the laws of the United States, without
                    regard to its conflict of law provisions.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about these Terms, please contact us at
                    support@answersolutions.net.
                </p>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="fast_nav container">
                <ul>
                    <li className="first">
                        2025 © AnswerSolutions LLC
                    </li>
                    <li>
                        <a href="#Products" role="button"
                           onClick={() => _this.props.navigate('Products')}>Products</a>
                    </li>
                    <li>
                        <a href="#run-andRead-product" role="button"
                           onClick={() => _this.props.navigate('RunAndReadProduct')}>Run & Read</a>
                    </li>
                    <li>
                        <a href="#runandreadpolicy" role="button"
                           onClick={() => _this.props.navigate('RunAndReadPolicy')}>Privacy Policy</a>
                    </li>
                    <li className="selected">
                        <a href="#runandreadeula" role="button"
                           onClick={() => _this.props.navigate('RunAndReadEULA')}>User Agreement</a>
                    </li>
                    <li>
                        <a href="#readSupport" role="button"
                           onClick={() => _this.props.navigate('RunAndReadSupport')}>Support</a>
                    </li>
                </ul>
            </div>
        </div>
    );
    }
}

export default RunAndReadUserAgreement;