import React, {Component} from 'react';
import './Home.css';
import ACallRecorderCompact from "./products/callrecorder/compact";
import RunAndReadCompact from "./products/runandread/compact";
import TimeSlotsCompact from "./products/timeslots/compact";
import UpWriteCompact from "./products/upwrite/compact";
import WiseTalkCompact from "./products/wisetalk/compact";
import StudyCardsCompact from "./products/studycards/compact";
import PhotoDjCompact from "./products/photodj/compact";
import AirSyncCompact from "./products/airsync/compact";
import BabyTubeCompact from "./products/babytube/compact";
import WifiCameraCompact from "./products/wificamera/compact";
import FooterNavCompact from "./navigation/footer";
import HeaderNavCompact from "./navigation/header";
import VoiceTranslatorCompact from "./products/translator/compact";

let _this;

class Products extends Component {
    constructor(props, context) {
        super(props);
        _this = this;

    }

    render() {
        return <div className="container">
            <HeaderNavCompact navigate={_this.props.navigate}/>
            <div className="card" id="shell4">
                <div id="card_content">
                    <p>
                        <i>We make great products that make people's life a bit better.</i>
                    </p>
                    <p>
                        <i>Our apps and services will help you find the answers to some of your questions and solutions
                            to some of your problems.</i>
                    </p>
                    <div className="fast_nav container">
                        <a href="https://github.com/sergenes" target="_blank"
                           rel="noopener noreferrer">
                            <svg style={{marginRight: "4"}}
                                 width="30" height="30" viewBox="0 0 24 24"
                                 fill="none">
                                <defs>
                                    <clipPath>
                                        <rect width="24" height="24" fill="currentColor"
                                        ></rect>
                                    </clipPath>
                                </defs>
                                <g clip-path="url(#clip0_405_286)">
                                    <path
                                        d="M12 0.297015C5.37075 0.297015 0 5.67226 0 12.297C0 17.6018 3.438 22.098 8.20275 23.6843C8.805 23.7975 9.0225 23.4263 9.0225 23.1053C9.0225 22.8203 9.01575 22.0665 9.01125 21.066C5.6715 21.789 4.968 19.4528 4.968 19.4528C4.42125 18.0698 3.63225 17.6985 3.63225 17.6985C2.54625 16.9568 3.7185 16.9725 3.7185 16.9725C4.9215 17.0543 5.5545 18.2063 5.5545 18.2063C6.62475 20.0423 8.3625 19.5113 9.05025 19.2023C9.15525 18.429 9.468 17.8973 9.8085 17.601C7.1445 17.3003 4.34325 16.269 4.34325 11.6708C4.34325 10.3575 4.80825 9.28801 5.577 8.44801C5.44425 8.14726 5.03775 6.92476 5.68275 5.27176C5.68275 5.27176 6.687 4.95151 8.98275 6.50251C9.9435 6.23626 10.9635 6.10351 11.9828 6.09676C13.0028 6.10351 14.022 6.23701 14.9827 6.50251C17.265 4.95151 18.2685 5.27176 18.2685 5.27176C18.9127 6.92476 18.5062 8.14651 18.3892 8.44801C19.1505 9.28801 19.6155 10.3583 19.6155 11.6708C19.6155 16.2795 16.8105 17.2958 14.1435 17.589C14.5605 17.9483 14.9512 18.687 14.9512 19.8083C14.9512 21.4133 14.9363 22.7063 14.9363 23.097C14.9363 23.409 15.1462 23.784 15.7642 23.6633C20.5642 22.0935 23.9985 17.5935 23.9985 12.2963C23.9985 5.67151 18.6277 0.296265 11.9985 0.296265L12 0.297015Z"
                                        fill="#000000"></path>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/in/sergey-neskoromny/" target="_blank"
                           rel="noopener noreferrer">
                            <svg style={{marginLeft: "4"}} width="32" height="32" viewBox="0 0 1024 1024">
                                <path fill="#0A66C2"
                                      d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM349.3 793.7H230.6V411.9h118.7v381.8zm-59.3-434a68.8 68.8 0 1 1 68.8-68.8c-.1 38-30.9 68.8-68.8 68.8zm503.7 434H675.1V608c0-44.3-.8-101.2-61.7-101.2-61.7 0-71.2 48.2-71.2 98v188.9H423.7V411.9h113.8v52.2h1.6c15.8-30 54.5-61.7 112.3-61.7 120.2 0 142.3 79.1 142.3 181.9v209.4z">
                                </path>
                            </svg>
                        </a>
                    </div>

                </div>
            </div>
            <br/>
            <RunAndReadCompact navigate={_this.props.navigate}/>
            <br/>
            <TimeSlotsCompact navigate={_this.props.navigate}/>
            <br/>
            <UpWriteCompact navigate={_this.props.navigate}/>
            <br/>
            <ACallRecorderCompact navigate={_this.props.navigate}/>
            <br/>
            <VoiceTranslatorCompact navigate={_this.props.navigate}/>
            <br/>
            <WiseTalkCompact navigate={_this.props.navigate}/>
            <br/>
            <StudyCardsCompact navigate={_this.props.navigate}/>
            <br/>
            <PhotoDjCompact navigate={_this.props.navigate}/>
            <br/>
            <AirSyncCompact navigate={_this.props.navigate}/>
            <br/>
            <WifiCameraCompact navigate={_this.props.navigate}/>
            {/*<br/>*/}
            {/*<BabyTubeCompact navigate={_this.props.navigate}/>*/}
            <br/>
            <FooterNavCompact navigate={_this.props.navigate}/>
        </div>
    }
}

export default Products;