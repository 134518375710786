import React, {Component} from 'react';
import '../../Home.css';
import ReactPlayer from "react-player";

let _this;

class VoiceTranslatorProduct extends Component {

    constructor(props, context) {
        super(props);
        _this = this;

    }

    componentDidMount() {
        // ReactGA.pageview('runandread');
    }

    render() {
        return <div className="container">
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#voice-translator-product" role="button"
                               onClick={() => _this.props.navigate('VoiceTranslatorProduct')}>Voice Translator</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/user-agreement"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/support"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card" id="shell4">
                <div id="card_content">
                    <div>
                        <img style={{borderRadius: '10%', margin: '0 16px 0 16px'}} className="roundrect"
                             src="/voicebridge192.png" align="left"
                             alt="app icon"
                             width="128px"/>
                        <div>
                            <h2>Speech Translator: Be Heard</h2>
                            <h3>Speak anywhere in the world and understand anyone, no matter what language they
                                speak.</h3>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <p>
                        Embark on a linguistic adventure with Speech Translator, inspired by "The Hitchhiker's Guide to
                        the Galaxy's" Babel Fish. This universal translator bridges language gaps, improving global
                        communication. Powered by your device's speech-to-text and text-to-speech engines, engage in
                        intuitive, voice-driven conversations. Seamlessly interact with advanced AI model, breaking down
                        barriers for enhanced understanding worldwide. Experience the future of communication today.

                        Our Difference - We Value and Fairly Charge Our Customers:
                        We prioritizes user privacy and convenience, performing speech recognition and synthesis
                        directly on your device—no need to send voice data to the cloud.
                        While most competing apps tie you down with subscriptions, we believe in a 'Pay as you Go'
                        philosophy. No hidden fees. No automatic renewals. We prioritize your needs, giving you the
                        power to decide when and for what you pay. Experience the freedom of only paying for what you
                        actually use.
                    </p>

                    <div>
                        Feature Highlights:
                        <ul>
                            <li> 1. Local Speech Processing: app prioritizes user privacy and convenience, performing
                                speech recognition and synthesis directly on your device—no need to send voice data to
                                the cloud.
                            </li>
                            <li> 2. Reliable Connectivity: Designed for optimal performance in areas with poor internet
                                connections, app exchanges text-only data to ensure a smooth user experience.
                            </li>
                            <li> 3. Multilingual Support: app embraces a global audience with its ability to recognize
                                and synthesize any language supported by device.
                            </li>
                            <li> 4. Token-Based Access: Enjoy an initial 10K tokens for free, and refill your balance
                                with affordable token packages.
                            </li>
                            <li> 5. Easy SignUp: One-Click Apple Registration: Seamless and speedy registration process
                                using your Apple account.
                            </li>
                        </ul>
                        Break language barriers with our Speech Translator app!
                        Speak freely anywhere, and understand everyone.
                        Simplify communication, expand horizons!
                        No Subscriptions.
                    </div>
                    <br/>
                    <div className="row">
                        <div className="column">
                            <img src="/voicebridge1.webp" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/voicebridge2.webp" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/voicebridge3.webp" alt="screenshot 3" width="100%"/>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div style={{margin: '0 24px 0 24px'}}>
                        <h4>WORKS ON IPHONE AND ANDROID</h4>
                    </div>
                    <div className="divider"></div>
                    <div className="product-links" style={{margin: '16px 0 0 16px'}}>
                        <a href="https://apps.apple.com/us/app/speech-translator-be-heard/id6479205508"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" alt="itunes" height="36" hspace="5" vspace="5"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.answersolutions.talkwise.voicebridge"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.svg" alt="google play" height="36" hspace="5" vspace="5"/>
                        </a>
                    </div>
                    <br/>
                    <br/>
                    <div align="center">
                        <a href="https://wisetalkapp.com">www.wisetalkapp.com</a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div align="center">
                    <ReactPlayer
                        url="https://youtu.be/fBEi_SoQFOc"
                    />
                </div>
            </div>
            <br/>
            <div align="center">
                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            <a href="#" role="button"
                               onClick={() => _this.props.navigate('Products')}>2025 © AnswerSolutions LLC</a>
                        </li>
                        <li className="selected">
                            <a href="#voice-translator-product" role="button"
                               onClick={() => _this.props.navigate('VoiceTranslatorProduct')}>Voice Translator</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/privacy-policy"
                               target="_blank"
                               rel="noopener noreferrer"
                            >Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/user-agreement"
                               target="_blank"
                               rel="noopener noreferrer">User Agreement</a>
                        </li>
                        <li>
                            <a href="https://wisetalkapp.com/support"
                               target="_blank"
                               rel="noopener noreferrer">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    }
}

export default VoiceTranslatorProduct;